import React, { useState, useEffect } from 'react';
import Layout from '../src/components/layout';
import Content from '../src/components/sections/workouts/content/workoutContent';
import Seo from '../src/components/seo';
import { graphql } from 'gatsby';
import TopBar from '../src/components/common/topBar';

const Workout = (props) => {
  const articleData = props.data.allStrapiWorkouts.edges[0].node;

  const isBrowser = typeof window !== 'undefined';
  return (
    <>
      <Layout>
        <Seo
          title={
            isBrowser && window && window.localStorage.getItem('lang') === 'ro'
              ? 'Tobsos - Antrenamente'
              : 'Tobsos - Workouts'
          }
          description={
            isBrowser && window && window.localStorage.getItem('lang') === 'ro'
              ? 'Cu cât fundația fizică a jucătorilor este mai puternică, cu atât sunt mai mari șansele de a deveni un sportiv de top.'
              : 'The stronger the players physical foundation the higher the chances of becoming a top athlete.'
          }
          keywords="fitness, training, sport, young, tineret"
          url="https://tobsos.com/"
        />
        <TopBar />
        <Content articleData={articleData} />
      </Layout>
    </>
  );
};
export const query = graphql`
  query($title: String!) {
    allStrapiWorkouts(filter: { title: { eq: $title } }) {
      edges {
        node {
          published_at
          roArticle
          title
          markdown
          feature_image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default Workout;
